import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { PermissionApiService } from '@mei/common/core/services/permission-api.service';
import { UserService } from '@mei/common/core/services/user.service';
import { filterNull } from '@mei/common/core/utils/rxjs/filter-null';
import { injectWebAppRoutes } from 'projects/web/src/app/features/shared/web-route-paths';
import { Observable, shareReplay, switchMap } from 'rxjs';

type MenuLink = {

	/** Link title. */
	readonly title: string;

	/** Link url. */
	readonly routerLink: string;

	/** Whether the link is available. */
	readonly isAvailable$?: Observable<boolean>;
};

/** Menu. */
@Component({
	selector: 'meiw-menu',
	templateUrl: './menu.component.html',
	styleUrl: './menu.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatTabsModule, MatButtonModule, RouterModule, CommonModule],
})
export class MenuComponent {
	private readonly routePaths = injectWebAppRoutes();

	private readonly permissionService = inject(PermissionApiService);

	private readonly userService = inject(UserService);

	private readonly canEditUsers$ = this.userService.currentUser$.pipe(
		filterNull(),
		switchMap(user => this.permissionService.canEditUsers(user.id)),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/** Placeholder links. */
	protected readonly links: readonly MenuLink[] = [
		{
			title: 'Home',
			routerLink: '#',
		},
		{
			title: 'Quoting',
			routerLink: this.routePaths.quoting.path,
		},
		{
			title: 'Customers',
			routerLink: '#',
		},
		{
			title: 'Safety',
			routerLink: '#',
		},
		{
			title: 'Location Management',
			routerLink: '#',
		},
		{
			title: 'Warehousing',
			routerLink: '#',
		},
		{
			title: 'Training',
			routerLink: '#',
		},
		{
			title: 'Resources',
			routerLink: this.routePaths.resources.path,
		},
		{
			title: 'IT',
			routerLink: '#',
		},
		{
			title: 'Admin',
			routerLink: '#',
		},
		{
			title: 'D365 Data View',
			routerLink: '#',
		},
		{
			title: 'User Management',
			routerLink: this.routePaths.users.path,
			isAvailable$: this.canEditUsers$,
		},
	];
}
